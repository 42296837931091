import { appMutationFactory } from '@api/api-slice';
import { IAnnualSupportCharge } from './annual-support-charge-list/annual-support-charge.interface';

type ServerResponse = { annualSupportCharges: IAnnualSupportCharge[] };

export const buildGenerateAnnualSupportChargeByQuote = appMutationFactory<number, ServerResponse>({
  query: (orderId) => ({
    url: `/asc_orders/${orderId}/generate_asc_by_quote`,
    method: 'POST',
  }),
});
