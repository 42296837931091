import React from 'react';
import { IOrderNew } from './order-new.interface';
import { Button, Grid } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { ModalChildProps } from '@components/AppModal';
import { sanitize, serialize } from './form-config';
import { useSaveOrderForm } from '../use-save-order-form';

type Props = {
  order: IOrderNew;
} & ModalChildProps;

export const OrderNew: React.FC<Props> = ({ order, onClose }) => {
  const {
    control,
    submitHandler,
    isSaveEnabled,
    operatorOptions,
    labelOptions,
    orderTypeNameOptions,
    isDropdownsLoading,
    statusOptions,
  } = useSaveOrderForm(order, sanitize, serialize, onClose);

  const buttonText = order.id ? 'Create Order' : 'Save Order';

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="userId"
            label="Operator"
            options={operatorOptions}
            isLoading={isDropdownsLoading}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="labels"
            label="Order Labels"
            options={labelOptions}
            isLoading={isDropdownsLoading}
            multiple
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="orderTypeId"
            label="Order Type"
            options={orderTypeNameOptions}
            isLoading={isDropdownsLoading}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledFileField
            fullWidth
            control={control}
            name="acceptanceLetterFile"
            label="Upload Acceptance"
            fileDetails={order.acceptanceFilePath}
            buttonLabel="Preview Acceptance"
            inputProps={{
              accept: '.pdf',
            }}
            isPreview
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="orderStatusId"
            options={statusOptions}
            label="Order Status"
            isLoading={isDropdownsLoading}
            isStatus
          />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
        </Grid>

        <Grid item xs={4} md={8} lg={12}>
          <Button fullWidth variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
