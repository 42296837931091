import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { ISensitivePersonalInformation } from '@features/order/needs-assessment/sensitive-personal-information/sensitive-personal-information.interface';
import { ApiModel } from './form-config';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { ISensitivePersonalInformationPresence } from './sensitive-personal-information-presence.interface';

export const buildGetSensitivePersonalInformationPresence = appQueryFactory<
  number,
  ISensitivePersonalInformationPresence
>({
  query: (id) => ({
    url: `/needs_assessment_orders/${id}/sensitive_personal_information`,
    method: 'GET',
  }),
});

interface ISensitivePersonalInformationRequest {
  id: number;
  reason: string;
}

type ISensitivePersonalInformationResponse = ISensitivePersonalInformation;

export const buildRequestSensitivePersonalInformation = appMutationFactory<
  ISensitivePersonalInformationRequest,
  ISensitivePersonalInformationResponse
>({
  query: ({ id, reason }) => ({
    url: `/needs_assessment_orders/${id}/sensitive_personal_information`,
    method: 'POST',
    data: { reason },
  }),
});

type SaveRequest = {
  id: number;
  payload: Partial<ApiModel>;
};

function createSPIPayload(payload: Partial<ApiModel>): ToSnakeCase<Partial<ApiModel>> {
  return modelToSnakeCase(payload);
}

export const buildSaveSensitivePersonalInformation = appMutationFactory<SaveRequest, ISensitivePersonalInformation>({
  query: ({ payload, id }) => ({
    url: `/needs_assessment_orders/${id}/sensitive_personal_information`,
    method: 'PATCH',
    data: createSPIPayload(payload),
  }),
});
