import { identity } from 'lodash';
import { Moment } from 'moment-timezone';
import { ISODateString } from '@utils/dates/iso-string.type';
import { toISODay } from '@utils/dates/to-iso-day';
import { sanitizeFactory } from '@utils/sanitize.factory';
import { serializeFactory } from '@utils/serialize.factory';
import { emptyStringToNull } from '@utils/empty-string-to-null';

export type FormModel = {
  startDate: Moment | null;
  endDate: Moment | null;
  tutorId: number[];
  isPaid: boolean | null;
  organisationId: number[];
  funderInvoice: string;
  tutorInvoice: string | null;
  sessionId: number | null;
  signedByTutor: boolean;
  signedByStudent: boolean;
};

export type ApiModel = {
  startDate: ISODateString | null;
  endDate: ISODateString | null;
  tutorId: number[];
  isPaid: string | null;
  organisationId: number[];
  funderInvoice: string | null;
  tutorInvoice: string | null;
  sessionId: number | null;
  signedByTutor: string | null;
  signedByStudent: string | null;
};

export const sanitize = sanitizeFactory<ApiModel, FormModel>({
  startDate: { type: 'date', initial: null },
  endDate: { type: 'date', initial: null },
  tutorId: { type: 'backend-label', optionKey: 'tutorOptions', multiple: true, initial: [] },
  isPaid: { type: 'transportable', initial: null },
  organisationId: { type: 'backend-label', optionKey: 'organisationOptions', multiple: true, initial: [] },
  funderInvoice: { type: 'transportable', initial: '' },
  tutorInvoice: { type: 'transportable', initial: '' },
  sessionId: { type: 'transportable', initial: null },
  signedByTutor: { type: 'transportable', initial: null },
  signedByStudent: { type: 'transportable', initial: null },
});

export const serialize = serializeFactory<ApiModel, FormModel>({
  startDate: toISODay,
  endDate: toISODay,
  tutorId: identity,
  isPaid: emptyStringToNull,
  organisationId: identity,
  funderInvoice: emptyStringToNull,
  tutorInvoice: emptyStringToNull,
  sessionId: identity,
  signedByTutor: emptyStringToNull,
  signedByStudent: emptyStringToNull,
});
