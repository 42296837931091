import React, { useCallback, useEffect, useState } from 'react';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { AddProduct } from '@features/product/add-product/AddProduct';
import { PrintQuote } from '@features/quote/PrintQuote';
import { ProductTable } from '@features/product/product-table/ProductTable';
import { IProductSummary } from '@features/product/product.interface';
import { QuoteStatusOptions, QuoteStatuses } from '@features/quote/quote-status.type';
import { useRole } from '@features/user/use-role';
import { UserRoles } from '@features/user/user-role.type';
import { CircularProgress, Grid } from '@mui/material';
import { useSaveQuote } from './use-save-quote';
import { WhyDisabled } from '@components/WhyDisabled';
import { IQuoteDetail } from '../quote.interface';
import { AssessorQuoteActionButton } from './AssessorQuoteActionButton';

const DisabledTitle = 'To edit quote fields first move it to Draft status';
const ReadonlyTitle = 'The quote is read only';

type Props = {
  orderId: number;
  quote: IQuoteDetail | null;
  setQuote: React.Dispatch<React.SetStateAction<IQuoteDetail | null>>;
  isLoading: boolean;
  isReadonly?: true;
};

export const SaveQuote: React.FC<Props> = ({ orderId, quote, setQuote, isLoading, isReadonly }) => {
  const { isLoading: isDropdownsLoading, ...backendLabels } = useAppBackendLabels([
    'assessmentCentreOptions',
    'quoteSupportPeriodOptions',
  ]);
  const [products, setProducts] = useState<IProductSummary[]>([]);
  const {
    submitHandler,
    control,
    isLoading: isSaving,
    isSaveDisabled,
  } = useSaveQuote(quote, setQuote, products, setProducts, orderId);

  const role = useRole();
  const isOperator = role === UserRoles.operator;

  useEffect(() => {
    if (!isLoading && quote) {
      setProducts(quote.quoteProducts);
    }
  }, [isLoading, quote]);

  const handleAddProduct = useCallback((product: IProductSummary) => setProducts(products.concat(product)), [products]);
  const handleDeleteProduct = useCallback(
    (id: number) => setProducts(products.filter((product) => product.id !== id)),
    [products],
  );

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  const isEditable = quote ? quote.status === QuoteStatuses.Draft : !isReadonly;
  const isStatusEditable = quote && isOperator;
  const isQuotePrintable = quote && quote.status !== QuoteStatuses.Draft;
  const disabledTitle = isReadonly ? ReadonlyTitle : DisabledTitle;

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: 1 }}>
        <Grid item xs={4} md={4} lg={12}>
          <WhyDisabled title={disabledTitle} disabled={!isEditable}>
            <ControlledAutocomplete
              control={control}
              name="supportPeriod"
              options={backendLabels.quoteSupportPeriodOptions}
              label="Support Period"
              isLoading={isDropdownsLoading}
              disabled={!isEditable}
              rules={{ required: true }}
            />
          </WhyDisabled>
        </Grid>
        <Grid item xs={4} md={4} lg={12}>
          <WhyDisabled title={disabledTitle} disabled={!isEditable}>
            <ControlledAutocomplete
              control={control}
              name="assessmentCentreId"
              options={backendLabels.assessmentCentreOptions}
              label="Assessment Centre"
              isLoading={isDropdownsLoading}
              disabled={!isEditable}
              rules={{ required: true }}
            />
          </WhyDisabled>
        </Grid>
        {isStatusEditable && (
          <Grid item xs={4} md={4} lg={12}>
            <ControlledAutocomplete
              control={control}
              name="status"
              options={QuoteStatusOptions}
              label="Status"
              isLoading={isDropdownsLoading}
              rules={{ required: true }}
            />
          </Grid>
        )}
      </Grid>
      {isEditable && <AddProduct onAddProduct={handleAddProduct} />}
      <ProductTable
        products={products}
        onDeleteProduct={handleDeleteProduct}
        quote={quote}
        isCompact
        isEditable={isEditable}
      />
      {isQuotePrintable && <PrintQuote products={products} quote={quote} />}
      <AppLoadingButton fullWidth type="submit" isLoading={isSaving} disabled={isSaveDisabled} sx={{ mt: 2 }}>
        Save Quote
      </AppLoadingButton>
      {quote && <AssessorQuoteActionButton quote={quote} setQuote={setQuote} setProducts={setProducts} />}
    </form>
  );
};
