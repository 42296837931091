import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse } from '@api/error-response.interface';
import { IMessageResponse } from '@api/message-response.interface';

export const buildCreateOrResetAccount = appMutationFactory<number, IErrorResponse | IMessageResponse>({
  query: (customerId) => ({
    url: `/students/${customerId}/create_or_reset_account`,
    method: 'POST',
  }),
});
