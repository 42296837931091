import { appQueryFactory } from '@api/api-slice';
import { IPaginable } from '@features/pagination/paginable.interface';
import { IProductSummary } from '@features/product/product.interface';

export type ProductsResponse = IPaginable<IProductSummary>;

type Params = { query: string };

export const buildGetSearchProducts = appQueryFactory<Params, ProductsResponse>({
  query: (params) => ({
    url: '/products',
    method: 'GET',
    params,
  }),
});
