import { appMutationFactory } from '@api/api-slice';

export interface ISignSessionRequest {
  nmh_session: {
    timesheet_file: File;
  };
}

export const buildUploadTimesheet = appMutationFactory<{ sessionId: number; file: File }, ISignSessionRequest>({
  query: ({ sessionId, file }) => ({
    url: `/sign_nmh_session?id=${sessionId}`, // TODO какого хрена это передается в квери, когда есть body?
    method: 'PATCH', // TODO вычистить snakifyKeys после исправления бекенда
    data: { nmh_session: { timesheet_file: file } },
  }),
});

interface IRegenerateTimesheetResponse {
  newDocumentPath: string;
}

export const buildRegenerateTimesheet = appMutationFactory<number, string>({
  query: (id) => ({
    url: `/nmh-sessions/${id}/generate-ts`,
    method: 'PATCH',
  }),
  transformResponse(response: IRegenerateTimesheetResponse) {
    return response.newDocumentPath;
  },
});
