import { appMutationFactory } from '@api/api-slice';
import { IAppointment } from '../appointment.interface';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';

type SubmitNoShowAppointmentRequest = {
  orderId: number;
  appointmentId: number | null;
};

type ServerResponse =
  | {
      needsAssessmentAppointment: IAppointment;
    }
  | IErrorResponse;

export const buildSubmitNoShow = appMutationFactory<SubmitNoShowAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ appointmentId, orderId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_appointments/${appointmentId}/no_show`,
    method: 'PATCH',
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.needsAssessmentAppointment;
  },
});
