import { appQueryFactory } from '@api/api-slice';
import { IAssessorAvailability } from '../assessor-availability.interface';
import { ISlotSummary } from '../slot-summary.interface';
import { TransportableModel } from '@models/transportable.type';
import { ApiModel } from './form-config';

type QueryParams = TransportableModel<ApiModel>;

interface IAssessorUtilisation {
  assessorAvailability: IAssessorAvailability[];
  slotSummary: ISlotSummary;
}

export const buildGetAssessorUtilisation = appQueryFactory<QueryParams, IAssessorUtilisation>({
  query: (params) => ({
    url: '/assessors/availability_report',
    method: 'GET',
    params,
  }),
});
