import { appQueryFactory } from '@api/api-slice';
import { IAppointment } from './appointment.interface';
import { TransportableModel } from '@models/transportable.type';
import { validateAppointments } from './spec/validate-appointments.spec';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';

export type FilterAppointmentsQueryParams = TransportableModel<{ orderId: number }>;
type ServerResponse =
  | {
      needsAssessmentAppointments: IAppointment[];
    }
  | IErrorResponse;
export type FilterAppointmentsResponse = IAppointment[] | IErrorResponse;
export const buildGetAppointments = appQueryFactory<FilterAppointmentsQueryParams, FilterAppointmentsResponse>({
  query: ({ orderId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_appointments`,
    method: 'GET',
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    validateAppointments(
      response.needsAssessmentAppointments,
      '/needs_assessment_orders/:orderId/needs_assessment_appointments',
    );
    return response.needsAssessmentAppointments;
  },
});
