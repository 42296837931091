import { appQueryFactory } from '@api/api-slice';

export type Announcement = {
  title: string;
  text: string;
  platforms: string[];
};

interface IAnnouncementResponse {
  announcement: Partial<Announcement>; //  The response can be empty {}
}

export const buildGetAnnouncement = appQueryFactory<void, IAnnouncementResponse>({
  query: () => ({ url: '/announcement', method: 'GET' }),
});
