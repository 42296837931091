import { appQueryFactory } from '@api/api-slice';
import { IHep } from './hep.interface';

export interface IHepResponse {
  hep: IHep;
}

export const buildGetHep = appQueryFactory<number, IHepResponse>({
  query: (id) => ({
    url: `/heps/${id}`,
    method: 'GET',
  }),
});
