import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { AssessmentCentreSummary, IAssessmentCentre } from './assessment-centre.interface';
import { validateAssessmentCentres } from './filter-assessment-centres-form/spec/validate-assessment-centres';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateAssessmentCentreDetail } from './spec/validate-assessment-centre-detail';

export type AssessmentCentreQueryParams = TransportableModel<PaginationArgs>;
export type AssessmentCentreSummaryResponse = IPaginable<AssessmentCentreSummary>;

const Endpoints = {
  assessmentCentreSummaries: '/assessment-centre-summaries',
  assessmentCentre: '/assessment-centre',
};

export const buildGetAssessmentCentresSummaries = appQueryFactory<
  AssessmentCentreQueryParams,
  AssessmentCentreSummaryResponse
>({
  query: (params) => ({
    url: Endpoints.assessmentCentreSummaries,
    method: 'GET',
    params,
  }),
  transformResponse(response: AssessmentCentreSummaryResponse) {
    validateAssessmentCentres(response, Endpoints.assessmentCentreSummaries);
    validatePagination(response, Endpoints.assessmentCentreSummaries);
    return response;
  },
});

export interface IAssessmentCentreResponse {
  assessmentCentre: IAssessmentCentre;
}

export const buildGetAssessmentCentre = appQueryFactory<number, IAssessmentCentreResponse>({
  query: (id) => ({
    url: `${Endpoints.assessmentCentre}/${id}`,
    method: 'GET',
  }),
  transformResponse(response: IAssessmentCentreResponse) {
    validateAssessmentCentreDetail(response, `${Endpoints.assessmentCentre}/:id`);
    return response;
  },
});
