import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { ITutor, ITutorSummary } from '@features/tutors/tutor.interface';
import { ITutorFeedbackSummary } from './tutor-feedback-summary.interface';
import { TransportableModel } from '@models/transportable.type';
import { validateTutors } from './spec/validate-tutors.spec';

const Endpoints = {
  tutors: '/tutors',
  tutorFeedbackSummaries: '/tutor-feedback-summaries',
} as const;

// TODO должно быть IPaginable<ITutor>
interface ITutorsResponse {
  tutors: ITutor[];
}

// TODO сразу ITutor
interface ITutorResponse {
  tutor: ITutorSummary;
}

export const buildGetTutors = appQueryFactory<void, ITutorsResponse>({
  query: () => ({ url: Endpoints.tutors, method: 'GET' }),
  transformResponse: (response: ITutorsResponse) => {
    validateTutors(response, Endpoints.tutors);
    return response;
  },
});

export const buildGetTutor = appQueryFactory<number, ITutorResponse>({
  query: (id) => ({ url: `${Endpoints.tutors}/${id}`, method: 'GET' }),
});

type TutorFeedbackSummariesQueryParams = TransportableModel<{ tutorId: number } & PaginationArgs>;
type TutorFeedbackSummariesResponse = IPaginable<ITutorFeedbackSummary>;
export const buildGetTutorFeedbackSummaries = appQueryFactory<
  TutorFeedbackSummariesQueryParams,
  TutorFeedbackSummariesResponse
>({
  query: ({ tutorId, ...params }) => ({
    url: `${Endpoints.tutorFeedbackSummaries}/${tutorId}`,
    method: 'GET',
    params,
  }),
});
