import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { ISessionInvoiceSummary } from '../session-invoice-summary.interface';
import { ApiModel } from './form-config';
import { validateFilterSessionInvoiceSummary } from './spec/validate-session-invoice-summaries.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { SessionSummariesEndpoint } from '@features/sessions/filter-sessions-form/api';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type SessionInvoiceSummariesResponse = IPaginable<ISessionInvoiceSummary>;
export const buildGetSessionInvoiceSummaries = appQueryFactory<QueryParams, SessionInvoiceSummariesResponse>({
  query: (params) => ({
    url: `${SessionSummariesEndpoint}?isFinance=true`,
    method: 'GET',
    params,
  }),
  transformResponse(response: SessionInvoiceSummariesResponse) {
    validateFilterSessionInvoiceSummary(response, SessionSummariesEndpoint);
    validatePagination(response, SessionSummariesEndpoint);
    return response;
  },
});
