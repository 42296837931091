import { appMutationFactory } from '@api/api-slice';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { IAppointmentInvoiceSummary } from './appointment-invoice-summary.interface';
import { BulkUpdateResponse } from '../bulk-update-response.type';
import { BulkUpdateRequest } from '../bulk-update-request.type';

type Response = BulkUpdateResponse<IAppointmentInvoiceSummary> | void;

type SupplierRequest = BulkUpdateRequest & { supplierInvoice: string };
export const buildBulkAddAppointmentSupplierInvoice = appMutationFactory<SupplierRequest, Response>({
  query: (payload) => ({
    url: `/needs_assessment_appointments/bulk-supplier-invoice-update`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkGenerateAppointmentXeroInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: `/needs_assessment_appointments/bulk_generate_appointment_xero_invoice`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendAppointmentInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: `/needs_assessment_appointments/bulk_send_needs_assessment_appointment_electronic_invoice`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
