import React from 'react';
import { NavLink } from 'react-router-dom';
import { GridColDef, GridFooter } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { Button, Link, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AppPaper } from '@components/AppPaper';
import { AppModal } from '@components/AppModal';
import { useFilteredOrderSummaries } from './filter-order-summaries-form/use-filtered-order-summaries';
import { useFilteredAssessmentOrderSummaries } from './filter-assessment-order-summaries-form/use-filtered-assessment-order-summaries';
import { createColumnFactory } from '@utils/create-column.factory';
import { Routes } from '@routes/routes';
import { FilterOrderSummariesForm } from './filter-order-summaries-form/FilterOrderSummariesForm';
import { FilterAssessmentOrderSummariesForm } from './filter-assessment-order-summaries-form/FilterAssessmentOrderSummariesForm';
import { useAppTitle } from '@hooks/use-app-title';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { BackendLabelGroup } from '@features/backend-label/BackendLabelGroup';
import { SaveCustomerForm } from '@features/customer/save-customer-form/SaveCustomerForm';
import { IOrderSummary } from './order-summary.interface';
import { UserRoles } from '@features/user/user-role.type';
import { useRole } from '@features/user/use-role';
import { CopyTableToClipboard } from '@components/CopyTableToClipboard';
import { RestrictRole } from '@features/auth/RestrictRole';
import { useToggle } from '@hooks/use-toggle';
import { Delay } from './Delay';
import { usePagination } from '@features/pagination/use-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createOrderSummaryColumn = createColumnFactory<IOrderSummary>();

const columns: GridColDef[] = [
  createOrderSummaryColumn('customerId', {
    width: 100,
    headerName: 'IDs',
    description: 'CustomerId/OrderId',
    renderCell: ({ row: summary }) => (
      <Link key={summary.id} component={NavLink} to={`${Routes.orderSummaries}/${summary.customerId}/${summary.id}`}>
        {summary.customerId}/{summary.id}
      </Link>
    ),
  }),
  createOrderSummaryColumn('customerFullName', { headerName: 'Customer', flex: 1 }),
  createOrderSummaryColumn('orderTypeId', {
    headerName: 'Type',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.orderTypeId} optionKey="orderTypeNameOptions" />,
    optionKey: 'orderTypeNameOptions',
  }),
  createOrderSummaryColumn('organisationId', {
    headerName: 'Organisation',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.organisationId} optionKey="organisationOptions" />,
    optionKey: 'organisationOptions',
  }),
  createOrderSummaryColumn('customerCrn', { headerName: 'CRN', width: 120 }),
  createOrderSummaryColumn('customerEmail', { headerName: 'Email', flex: 1 }),
  createOrderSummaryColumn('customerPhone', { headerName: 'Phone', width: 120 }),
  createOrderSummaryColumn('customerPostcode', { headerName: 'Postcode', width: 80 }),
  createOrderSummaryColumn('orderStatusId', {
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabel value={summary.orderStatusId} optionKey="statusOptions" />,
    optionKey: 'statusOptions',
  }),
  createOrderSummaryColumn('labels', {
    headerName: 'Labels',
    flex: 1,
    renderCell: ({ row: summary }) => <BackendLabelGroup labels={summary.labels} optionKey="labelOptions" />,
    optionKey: 'labelOptions',
  }),
  createOrderSummaryColumn('daysInLastStatus', {
    headerName: 'Delay',
    width: 80,
    renderCell: ({ row: summary }) => <Delay delay={summary.daysInLastStatus} orderStatusId={summary.orderStatusId} />,
  }),
];

/**
 * This component uses different logic: hooks and filter and customer creation form components based on user role.
 */
export const OrderSummariesPage: React.FC = () => {
  const role = useRole();
  useAppTitle('Orders');
  const isAssessor = role === UserRoles.assessor;
  const isQA = role === UserRoles.qualityAssurance;
  const useFilteredSummaries = isAssessor || isQA ? useFilteredAssessmentOrderSummaries : useFilteredOrderSummaries;
  const {
    entries: orderSummaries,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = usePagination(useFilteredSummaries, PageSizeNames.OrderSummary);
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  return (
    <AppPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Typography variant="h4" component="h1">
          Orders
        </Typography>
        <RestrictRole roles={[UserRoles.assessor, UserRoles.qualityAssurance]}>
          <Button variant="outlined" size="small" onClick={toggleIsModalOpen} startIcon={<AddIcon />}>
            Create Customer
          </Button>
        </RestrictRole>
      </Stack>
      <RestrictRole
        roles={[UserRoles.assessor, UserRoles.qualityAssurance]}
        AlternateComponent={<FilterAssessmentOrderSummariesForm />}
      >
        <FilterOrderSummariesForm />
      </RestrictRole>
      <AppDataGrid
        rows={orderSummaries}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack direction="row" sx={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
              <CopyTableToClipboard rows={orderSummaries} columns={columns} />
              <GridFooter sx={{ flexGrow: 1, border: 'none' }} />
            </Stack>
          ),
        }}
      />
      <AppModal open={isModalOpen} onClose={toggleIsModalOpen} title="Create Customer" width="62vw">
        <SaveCustomerForm />
      </AppModal>
    </AppPaper>
  );
};
