import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse } from '@api/error-response.interface';
import { IMessageResponse } from '@api/message-response.interface';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';
import { INotPostedProduct } from './not-posted-product.interface';

type Response = {
  salesId: IOrderTechnical['salesId'];
  notPostedProducts: INotPostedProduct[];
} & IMessageResponse;

export const buildGenerateSalesOrderId = appMutationFactory<number, Response | IErrorResponse>({
  query: (orderId) => {
    return {
      url: `/order/${orderId}/generate_tech_order_sales_order`,
      method: 'POST',
    };
  },
});
