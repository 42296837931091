import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { IOrder } from './order.interface';

const ordersAdapter = createEntityAdapter<IOrder>({
  selectId: (entry) => entry.id,
});

const ordersSlice = createSlice({
  name: 'Orders',
  initialState: ordersAdapter.getInitialState(),
  reducers: {
    upsertOrders: ordersAdapter.upsertMany,
    upsertOrder: ordersAdapter.upsertOne,
    updateOrder: ordersAdapter.updateOne,

    //Backend can't send info about 'canGenerateAsc' field.
    disableAscOrderGeneration: (state, action: PayloadAction<number>) => {
      ordersAdapter.updateOne(state, {
        id: action.payload,
        changes: { canGenerateAsc: false },
      });
    },
  },
});

export const OrdersActions = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;
export type OrdersActionsType = typeof OrdersActions;

export const { selectAll, selectById } = ordersAdapter.getSelectors((state: RootState) => state.orders);
