import { appQueryFactory } from '@api/api-slice';
import { Endpoint } from '../api';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IQuote } from '../quote.interface';

export type QuotesQueryParams = TransportableModel<PaginationArgs>;
export type QuotesResponse = IPaginable<IQuote>;

export const buildGetQuotes = appQueryFactory<QuotesQueryParams, QuotesResponse>({
  query: (params) => ({
    url: Endpoint,
    method: 'GET',
    params,
  }),
});
