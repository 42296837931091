import { appMutationFactory } from '@api/api-slice';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { ISessionInvoiceSummary } from './session-invoice-summary.interface';
import { BulkUpdateResponse } from '../bulk-update-response.type';
import { BulkUpdateRequest } from '../bulk-update-request.type';

type Response = BulkUpdateResponse<ISessionInvoiceSummary> | void;

type SupplierRequest = BulkUpdateRequest & { supplierInvoice: string };
export const buildBulkAddSessionSupplierInvoice = appMutationFactory<SupplierRequest, Response>({
  query: (payload) => ({
    url: `/nmh-sessions/bulk-supplier-invoice-update`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkGenerateSessionXeroInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: `/nmh-sessions/bulk_generate_session_xero_invoice`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});

export const buildBulkSendSessionInvoice = appMutationFactory<BulkUpdateRequest, Response>({
  query: (payload) => ({
    url: `/nmh-sessions/bulk_send_nmh_session_electronic_invoice`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
});
