import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IAppointmentInvoiceSummary } from '../appointment-invoice-summary.interface';
import { ApiModel } from './form-config';
import { validateFilterAppointmentInvoiceSummary } from './spec/validate-appointment-invoice-summaries.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { AppointmentSummariesEndpoint } from '@features/appointment-summaries/filter-appointment-summaries/api';

export type QueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type AppointmentInvoiceSummariesResponse = IPaginable<IAppointmentInvoiceSummary>;
export const buildGetAppointmentInvoiceSummaries = appQueryFactory<QueryParams, AppointmentInvoiceSummariesResponse>({
  query: (params) => ({
    url: `${AppointmentSummariesEndpoint}?isFinance=true`,
    method: 'GET',
    params,
  }),
  transformResponse(response: AppointmentInvoiceSummariesResponse) {
    validateFilterAppointmentInvoiceSummary(response, AppointmentSummariesEndpoint);
    validatePagination(response, AppointmentSummariesEndpoint);
    return response;
  },
});
