import React, { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthState, IPassword } from './Reset';
import { useAppTitle } from '@hooks/use-app-title';
import { useToggle } from '@hooks/use-toggle';

type Props = {
  handleNext: (step: number) => void;
  password: IPassword;
  setPassword: Dispatch<SetStateAction<IPassword>>;
};

export const ConfirmPassword: React.FC<Props> = ({ handleNext, setPassword, password }) => {
  const [isPasswordVisible, toggleIsPasswordVisible] = useToggle();
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useAppTitle('Confirm Password');
  const onSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (password.password !== password.password_confirmation) {
      setErrorMessage('Password and Confirm Password should be same');
    } else {
      handleNext(AuthState.ConfirmCode);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Chose a secure password for your account. Minimum 8 characters, including one symbol, upper and lower case and
          number.
        </Typography>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
          <OutlinedInput
            value={password.password}
            onChange={(event): void => setPassword((prev) => ({ ...prev, password: event.target.value }))}
            inputProps={{ minLength: 8 }}
            id="outlined-adornment-password"
            type={isPasswordVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={toggleIsPasswordVisible}>
                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            name="password"
            required
          />
        </FormControl>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password-confirm">Confirm new password</InputLabel>
          <OutlinedInput
            value={password.password_confirmation}
            required
            inputProps={{ minLength: 8 }}
            id="outlined-adornment-password-confirm"
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={(): void => setIsConfirmPasswordVisible(() => !isConfirmPasswordVisible)}>
                  {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm password"
            name="password_confirmation"
            onChange={(event): void => setPassword((prev) => ({ ...prev, password_confirmation: event.target.value }))}
          />
        </FormControl>
        {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <Button variant="contained" size="large" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
};
