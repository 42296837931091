import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IProductWithCondition } from './product.interface';
import { validateProductDetail } from './spec/validate-product-detail.spec';
import { ApiModel } from './product-details/form-config';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { IErrorResponse } from '@api/error-response.interface';

const Endpoint = '/products';

export interface IProductWithConditionResponse {
  product: IProductWithCondition;
}

type ProductPayload = {
  product_update: ToSnakeCase<Partial<ApiModel>>;
};

function createProductPayload(payload: Partial<ApiModel>): ProductPayload {
  return {
    product_update: {
      ...modelToSnakeCase(payload),
    },
  };
}

export const buildGetProduct = appQueryFactory<number, IProductWithConditionResponse>({
  query: (id) => ({
    url: `${Endpoint}/${id}`,
    method: 'GET',
  }),
  transformResponse(response: IProductWithConditionResponse) {
    validateProductDetail(response, `${Endpoint}/:id`);
    return response;
  },
});

type SaveProductRequest = {
  id: number;
  payload: Partial<ApiModel>;
};

type SaveProductResponse = {
  product: IProductWithCondition;
};
export const buildSaveProduct = appMutationFactory<SaveProductRequest, SaveProductResponse | IErrorResponse>({
  query: ({ payload, id }) => ({
    url: `${Endpoint}/${id}/update`,
    method: 'PATCH',
    data: createProductPayload(payload),
  }),
});
