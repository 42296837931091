import React from 'react';
import { IOrderUnsupportedCustomer } from './order-unsupported-customer.interface';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { sanitize, serialize } from './form-config';
import { useSaveOrderForm } from '../use-save-order-form';
import { OrderQuote } from '@features/quote/OrderQuote';

type Props = {
  order: IOrderUnsupportedCustomer;
};

export const OrderUnsupportedCustomer: React.FC<Props> = ({ order }) => {
  const { control, submitHandler, isSaveEnabled, operatorOptions, labelOptions, isDropdownsLoading, statusOptions } =
    useSaveOrderForm(order, sanitize, serialize);

  return (
    <form onSubmit={submitHandler}>
      <Box>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="userId"
              options={operatorOptions}
              label="Operator"
              isLoading={isDropdownsLoading}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="labels"
              options={labelOptions}
              label="Order Labels"
              isLoading={isDropdownsLoading}
              multiple
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledAutocomplete
              control={control}
              name="orderStatusId"
              options={statusOptions}
              label="Order Status"
              isLoading={isDropdownsLoading}
              isStatus
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledFileField
              fullWidth
              control={control}
              name="acceptanceLetterFile"
              label="Upload Acceptance"
              fileDetails={order.acceptanceFilePath}
              buttonLabel="Preview Acceptance"
              inputProps={{
                accept: '.pdf',
              }}
              isPreview
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 1 }}>
          Onboarding Details
        </Typography>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledTextField control={control} name="quoteId" label="Quote ID" />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <OrderQuote orderId={order.id} quoteId={order.quoteId} />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <ControlledTextField control={control} name="funderInvoice" label="Funder Invoice" />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={3} fullWidth />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button fullWidth variant="contained" size="large" type="submit" disabled={!isSaveEnabled}>
          Save Order
        </Button>
      </Box>
    </form>
  );
};
