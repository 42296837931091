import { appMutationFactory } from '@api/api-slice';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { ApiModel } from './form-config';
import { IAnnualSupportCharge } from '../annual-support-charge-list/annual-support-charge.interface';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';

type SaveAnnualSupportChargeRequest = {
  orderId: number;
  annualSupportChargeId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { annualSupportCharge: IAnnualSupportCharge } | IErrorResponse;

export const buildCreateAnnualSupportChargeManually = appMutationFactory<
  SaveAnnualSupportChargeRequest,
  IAnnualSupportCharge | IErrorResponse
>({
  query: ({ orderId, payload }) => ({
    url: `/asc_orders/${orderId}/annual_support_charges`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.annualSupportCharge;
  },
});

export const buildSaveAnnualSupportCharge = appMutationFactory<
  SaveAnnualSupportChargeRequest,
  IAnnualSupportCharge | IErrorResponse
>({
  query: ({ payload, orderId, annualSupportChargeId }) => ({
    url: `/asc_orders/${orderId}/annual_support_charges/${annualSupportChargeId}`,
    method: 'PATCH',
    data: modelToSnakeCase(payload),
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.annualSupportCharge;
  },
});
