import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { HepSummary } from '../hep.interface';
import { appQueryFactory } from '@api/api-slice';

export type HepQueryParams = TransportableModel<PaginationArgs>;
export type HepResponse = IPaginable<HepSummary>;

export const buildGetHeps = appQueryFactory<HepQueryParams, HepResponse>({
  query: (params) => ({
    url: '/heps',
    method: 'GET',
    params,
  }),
});
