import React from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ICustomer } from '@features/customer/customer.interface';
import { PhonePattern } from '@utils/validation-rules';
import { useSaveCustomerForm } from './use-save-customer-form';
import { UserRoles } from '@features/user/user-role.type';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { useDisableRole } from '@features/auth/use-disable-role';

type Props = {
  customer?: ICustomer | void;
};

export const SaveCustomerForm: React.FC<Props> = ({ customer }) => {
  const {
    submitHandler,
    isSaveEnabled,
    isLabelsLoading,
    buttonText,
    control,
    fundingBodyOptions,
    hepOptions,
    preferredCommunicationWaysOptions,
  } = useSaveCustomerForm(customer);

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="crn"
            label="Customer Reference Number"
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="firstName"
            label="First Name *"
            rules={{ required: 'First name is required' }}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="lastName"
            label="Last Name *"
            rules={{ required: 'Last name is required' }}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="email"
            label="Email *"
            rules={{ required: 'Email is required' }}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="contactNumber"
            label="Telephone Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="altNumber"
            label="Alternative Number"
            rules={{ pattern: PhonePattern }}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="address"
            label="Delivery Address"
            multiline
            minRows={2}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledTextField
            control={control}
            name="postcode"
            label="Postcode"
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="fundingBodyId"
            label="Funding Body"
            options={fundingBodyOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="hepId"
            label="HEP"
            options={hepOptions}
            isLoading={isLabelsLoading}
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>

        <Grid item xs={4} md={4} lg={4}>
          <ControlledAutocomplete
            control={control}
            name="prefCommunicationWay"
            label="Preferred Communication Method"
            options={preferredCommunicationWaysOptions}
            isLoading={isLabelsLoading}
            multiple
            disabled={isDisabledForAssessorAndQA}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <ControlledToggleButton
            control={control}
            fullWidth
            name="extraMile"
            getLabel={(value: boolean): string => (value ? 'Extra Mile' : 'Not Extra Mile')}
          />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <ControlledTextField control={control} name="notes" label="Notes" multiline minRows={2} />
        </Grid>
        <Grid item xs={4} md={8} lg={12}>
          <Button variant="contained" fullWidth size="large" type="submit" disabled={!isSaveEnabled}>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
