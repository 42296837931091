import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IReport } from './report.interface';
import { TransportableModel } from '@models/transportable.type';
import { validateReports } from './spec/validate-reports.spec';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { ApiModel } from './send-report/re-send-report/form.config';

export type FilterReportHistoryQueryParams = TransportableModel<{ orderId: number }>;
type ServerResponse = { needsAssessmentReports: IReport[] } | IErrorResponse;
export type FilterReportHistoryResponse = IReport[] | IErrorResponse;

export const buildGetReports = appQueryFactory<FilterReportHistoryQueryParams, FilterReportHistoryResponse>({
  query: ({ orderId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_reports`,
    method: 'GET',
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    validateReports(response.needsAssessmentReports, '/needs_assessment_orders/:orderId/needs_assessment_reports');
    return response.needsAssessmentReports;
  },
});

export type SendReportRequest = {
  orderId: number;
  payload?: Partial<ApiModel>;
};

function createReportPayload(payload: Partial<ApiModel>): Partial<ToSnakeCase<ApiModel>> {
  return {
    ...modelToSnakeCase(payload),
  };
}

export const buildSendReport = appMutationFactory<SendReportRequest, void | IErrorResponse>({
  query: ({ orderId, payload }) => ({
    url: `/needs_assessment_orders/${orderId}/send_final_report`,
    method: 'POST',
    data: payload && createReportPayload(payload),
  }),
});

export type SendConvertToPdfReportRequest = {
  orderId: number;
  reportId: number;
};

export type ConvertToPdfResponse = {
  needsAssessmentReports: IReport[];
};

export const buildConvertToPdfReport = appMutationFactory<
  SendConvertToPdfReportRequest,
  ConvertToPdfResponse | IErrorResponse
>({
  query: ({ orderId, reportId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_reports/${reportId}/convert_to_pdf`,
    method: 'POST',
  }),
});
