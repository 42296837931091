import { appQueryFactory } from '@api/api-slice';
import { IAnnualSupportCharge } from './annual-support-charge.interface';

type AnnualSupportChargeListResponse = {
  annualSupportCharges: IAnnualSupportCharge[];
};

export const buildGetAnnualSupportChargeList = appQueryFactory<number, AnnualSupportChargeListResponse>({
  query: (orderId) => ({
    url: `/asc_orders/${orderId}/annual_support_charges`,
    method: 'GET',
  }),
});
