import { appQueryFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { cureWrongISODate } from './cure-wrong-iso-date';
import { ISlot } from './slot.interface';
import { ISODateTimeString } from '@utils/dates/iso-string.type';

type AppointmentZohoSlotsQuery = {
  orderId: number;
  qualification?: never;
  tutorId?: never;
};

type NmhZohoSlotsQuery = {
  orderId?: never;
  qualification: string | number;
  tutorId?: number | null;
};

export type ZohoSlotsQuery = AppointmentZohoSlotsQuery | NmhZohoSlotsQuery;

type ZohoSlotsQueryWithDateRange = ZohoSlotsQuery & { from: string; to: string };

type ZohoSlotsResponse = {
  freeSlots: ISlot[];
  offeredDate: ISODateTimeString;
};

export const buildGetZohoFreeSlots = appQueryFactory<ZohoSlotsQueryWithDateRange, ZohoSlotsResponse | IErrorResponse>({
  query: (params) => ({
    url: `/zoho_free_slots?${createQuery(params)}`,
    method: 'GET',
  }),
  transformResponse,
});

function createQuery({ orderId, from, to, qualification, tutorId }: ZohoSlotsQueryWithDateRange): string {
  const params: string[] = [`from=${from}`, `to=${to}`];
  if (orderId) {
    params.push(`orderId=${orderId}`);
  } else if (tutorId) {
    params.push(`tutorId=${tutorId}`);
  } else {
    params.push(`qualification=${qualification}`);
  }
  return params.join('&');
}

function transformResponse(response: ZohoSlotsResponse | IErrorResponse): ZohoSlotsResponse | IErrorResponse {
  if (isErrorResponse(response)) {
    return response;
  }
  return {
    offeredDate: response.offeredDate,
    // TODO remove this shit when backend return proper ISO dates
    freeSlots: response.freeSlots.map(({ start, end }) => ({
      start: cureWrongISODate(start),
      end: cureWrongISODate(end),
    })),
  };
}
