import { appQueryFactory } from '@api/api-slice';
import { ICustomer } from '@features/customer/customer.interface';
import { validateCustomer } from './spec/validate-customer.spec';

export interface ICustomerResponse {
  student: ICustomer;
}

const Endpoint = '/students';

export const buildGetCustomer = appQueryFactory<number, ICustomer>({
  query: (orderId) => ({ url: `${Endpoint}/${orderId}`, method: 'GET' }),
  transformResponse: (response: ICustomerResponse) => {
    validateCustomer(response.student, Endpoint);
    return response.student;
  },
});
