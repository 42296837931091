import { appQueryFactory } from '@api/api-slice';
import { IQuickFilterTab } from './quick-filter-tab.interface';
import { QuickFilterTabsType } from './quick-filter-tabs.type';

type QuickFilterTabsResponse = { tabs: IQuickFilterTab[] };

export const buildGetQuickFilterTabs = appQueryFactory<QuickFilterTabsType, QuickFilterTabsResponse>({
  query: (type) => ({
    url: `/filter-tabs?type=${type}`,
    method: 'GET',
  }),
});
