import { appMutationFactory } from '@api/api-slice';
import { IAllocation } from '@features/allocations/allocation.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';
import { ApiModel } from './form-config';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';

interface IAllocationResponse {
  nmhAllocation: IAllocation;
}

type AllocationPayload = {
  nmh_allocation: ToSnakeCase<Partial<ApiModel>>;
};

type SaveAllocationRequest = {
  id: number | null;
  payload: Partial<ApiModel>;
};

const Endpoint = '/nmh_allocations';

export const buildCreateAllocation = appMutationFactory<SaveAllocationRequest, IAllocation | IErrorResponse>({
  query: ({ payload }) => ({
    url: `${Endpoint}`,
    method: 'POST',
    data: createAllocationPayload(payload),
  }),
  transformResponse,
});

export const buildSaveAllocation = appMutationFactory<SaveAllocationRequest, IAllocation | IErrorResponse>({
  query: ({ payload, id }) => ({
    url: `${Endpoint}/${id}`,
    method: 'PATCH',
    data: createAllocationPayload(payload),
  }),
  transformResponse,
});

function createAllocationPayload(allocation: Partial<ApiModel>): AllocationPayload {
  return { nmh_allocation: modelToSnakeCase(allocation) };
}

function transformResponse(response: IAllocationResponse | IErrorResponse): IAllocation | IErrorResponse {
  return isErrorResponse(response) ? response : response.nmhAllocation;
}
