import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { ISessionSummary } from '@features/sessions/session.interface';
import { TransportableModel } from '@models/transportable.type';
import { ApiModel } from './form-config';
import { validateSessionSummaries } from './spec/validate-session-summaries.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';

export const SessionSummariesEndpoint = '/v2/nmh-sessions-summaries';
export type FilterSessionsQueryParams = TransportableModel<ApiModel & PaginationArgs>;
export type FilterSessionsResponse = IPaginable<ISessionSummary>;
export const buildGetSessions = appQueryFactory<FilterSessionsQueryParams, FilterSessionsResponse>({
  query: (params) => ({
    url: SessionSummariesEndpoint,
    method: 'GET',
    params,
  }),
  transformResponse(response: FilterSessionsResponse) {
    validateSessionSummaries(response, SessionSummariesEndpoint);
    validatePagination(response, SessionSummariesEndpoint);
    return response;
  },
});
