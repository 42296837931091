import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse } from '@api/error-response.interface';
import { IElectronicInvoiceLog } from '@features/electronic-invoice/electronic-invoice-log.interface';

export const Endpoints = {
  refresh: 'regenerate_needs_assessment_appointment_electronic_invoice',
  send: 'send_needs_assessment_appointment_electronic_invoice',
  generate: 'generate_appointment_xero_invoice',
};

export const buildRefreshAppointmentElectronicInvoice = appMutationFactory<number, string | IErrorResponse>({
  query: (id) => ({
    url: `/needs_assessment_appointments/${id}/${Endpoints.refresh}`,
    method: 'POST',
  }),
});

type SendElectronicInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildSendAppointmentElectronicInvoice = appMutationFactory<number, SendElectronicInvoiceResponse>({
  query: (id) => ({
    url: `/needs_assessment_appointments/${id}/${Endpoints.send}`,
    method: 'POST',
  }),
});

type GenerateXeroInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildGenerateAppointmentElectronicInvoice = appMutationFactory<number, GenerateXeroInvoiceResponse>({
  query: (id) => ({
    url: `/needs_assessment_appointments/${id}/${Endpoints.generate}`,
    method: 'POST',
  }),
});
