import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { IProductSummary } from '@features/product/product.interface';
import { IQuoteDetail } from '@features/quote/quote.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';

type QuoteSummary = Partial<
  Pick<IQuoteDetail, 'supportPeriod' | 'assessmentCentreId' | 'status'> & {
    orderId: number;
    products: Pick<IProductSummary, 'id' | 'quantity'>[];
  }
>;

type CreateRequest = {
  quoteInternalId: string | null;
  quote: QuoteSummary;
};

type SaveRequest = {
  quoteInternalId: string | null;
  quote: QuoteSummary;
};

type Response = { quote: IQuoteDetail };

function transformResponse(response: IErrorResponse | Response): IErrorResponse | IQuoteDetail {
  return isErrorResponse(response) ? response : response.quote;
}

function createPayload(quote: QuoteSummary): ToSnakeCase<QuoteSummary> {
  return modelToSnakeCase(quote);
}

export const buildCreateQuote = appMutationFactory<CreateRequest, IErrorResponse | IQuoteDetail>({
  query: ({ quote }) => ({
    url: '/quotes',
    method: 'POST',
    data: createPayload(quote),
  }),
  transformResponse,
});

export const buildSaveQuote = appMutationFactory<SaveRequest, IErrorResponse | IQuoteDetail>({
  query: ({ quoteInternalId, quote }) => ({
    url: `/quotes/${quoteInternalId}`,
    method: 'PATCH',
    data: createPayload(quote),
  }),
  transformResponse,
});
