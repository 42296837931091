import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from './form-config';
import { IReport } from '../report.interface';
import { modelToSnakeCase } from '@utils/model-to-snake-case';

type SaveReportRequest = {
  orderId: number;
  reportId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { needsAssessmentReport: IReport } | IErrorResponse;

export const buildCreateReport = appMutationFactory<SaveReportRequest, IReport | IErrorResponse>({
  query: ({ payload, orderId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_reports`,
    method: 'POST',
    data: { needs_assessment_report: modelToSnakeCase(payload) },
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.needsAssessmentReport;
  },
});

export const buildSaveReport = appMutationFactory<SaveReportRequest, IReport | IErrorResponse>({
  query: ({ payload, orderId, reportId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_reports/${reportId}`,
    method: 'PATCH',
    data: { needs_assessment_report: modelToSnakeCase(payload) },
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.needsAssessmentReport;
  },
});
