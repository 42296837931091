import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse } from '@api/error-response.interface';
import { IMessageResponse } from '@api/message-response.interface';
import { IOrderTechnical } from '@features/order/technical/order-technical.interface';

export type Response = {
  invoiceNumber: IOrderTechnical['invoiceNumber'];
} & IMessageResponse;

export const buildGenerateInvoiceNumber = appMutationFactory<number, Response | IErrorResponse>({
  query: (orderId) => {
    return {
      url: `/order/${orderId}/generate_tech_order_xero_invoice`,
      method: 'POST',
    };
  },
});
