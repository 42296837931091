import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IZohoAuthorizationState } from './zoho.slice';

interface IZohoAuthorizationResponse {
  uri: string;
  isLoggedIn: boolean;
  zohoMail: string;
}

export const buildGetZohoLink = appQueryFactory<string, IZohoAuthorizationState>({
  query: (userId) => ({
    url: `/zoho_authorization/${userId}`,
    method: 'GET',
  }),
  transformResponse: (response: IZohoAuthorizationResponse) => ({
    isLoggedIn: response.isLoggedIn,
    authorizationUri: response.uri,
    zohoUserEmail: response.zohoMail,
  }),
});

export const buildRequestZohoLogout = appMutationFactory<string, void>({
  query: (id) => ({
    url: `/users/${id}/delete_zoho_token`,
    method: 'POST',
  }),
});
