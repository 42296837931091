import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IProduct } from '../product.interface';
import { validateProducts } from './spec/validate-product.spec';
import { validatePagination } from '@features/pagination/spec/pagination.spec';

const Endpoint = '/products';

export type ProductsQueryParams = TransportableModel<PaginationArgs>;
export type ProductsResponse = IPaginable<IProduct>;

export const buildGetProducts = appQueryFactory<ProductsQueryParams, ProductsResponse>({
  query: (params) => ({
    url: Endpoint,
    method: 'GET',
    params,
  }),
  transformResponse(response: ProductsResponse) {
    validateProducts(response, Endpoint);
    validatePagination(response, Endpoint);
    return response;
  },
});
