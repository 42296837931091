import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IWeekTimeSlots } from '@models/week-time-slots.interface';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';

export type AssessorsSlotsResponse = {
  crmFreeSlots: IWeekTimeSlots;
};

export const buildGetAssessorFreeSlots = appQueryFactory<number, IWeekTimeSlots | IErrorResponse>({
  query: (id) => ({
    url: `/assessors/${id}/show_crm_free_slots`,
    method: 'GET',
  }),
  transformResponse,
});

type SaveAssessorsSlotsRequest = {
  assessorId: number;
  payload: IWeekTimeSlots;
};

function createPayload(slots: IWeekTimeSlots): { crm_free_slots: ToSnakeCase<IWeekTimeSlots> } {
  return { crm_free_slots: modelToSnakeCase(slots) };
}

export const buildSaveAssessorFreeSlots = appMutationFactory<
  SaveAssessorsSlotsRequest,
  IWeekTimeSlots | IErrorResponse
>({
  query: ({ assessorId, payload }) => ({
    url: `/assessors/${assessorId}/update_crm_free_slots`,
    method: 'PATCH',
    data: createPayload(payload),
  }),
  transformResponse,
});

function transformResponse(response: AssessorsSlotsResponse): IWeekTimeSlots | IErrorResponse {
  return isErrorResponse(response) ? response : response.crmFreeSlots;
}
