import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { IErrorResponse, isErrorResponse } from '@api/error-response.interface';
import { ApiModel } from './form-config';
import { IAppointment } from '../appointment.interface';
import { modelToSnakeCase } from '@utils/model-to-snake-case';
import { IAssessorOption } from '@features/backend-label/user-label.interface';
import { orderBy } from 'lodash';

type SaveAppointmentRequest = {
  orderId: number;
  appointmentId: number | null;
  payload: Partial<ApiModel>;
};

type ServerResponse = { needsAssessmentAppointment: IAppointment } | IErrorResponse;

export const buildCreateAppointment = appMutationFactory<SaveAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ payload, orderId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_appointments`,
    method: 'POST',
    data: modelToSnakeCase(payload),
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.needsAssessmentAppointment;
  },
});

export const buildSaveAppointment = appMutationFactory<SaveAppointmentRequest, IAppointment | IErrorResponse>({
  query: ({ payload, orderId, appointmentId }) => ({
    url: `/needs_assessment_orders/${orderId}/needs_assessment_appointments/${appointmentId}`,
    method: 'PATCH',
    data: modelToSnakeCase(payload),
  }),
  transformResponse(response: ServerResponse) {
    if (isErrorResponse(response)) {
      return response;
    }
    return response.needsAssessmentAppointment;
  },
});

export const buildGetFilteredAssessorsForOrderId = appQueryFactory<number, IAssessorOption[] | IErrorResponse>({
  query: (orderId) => ({
    url: `/assessors/labels_by_disability_types/${orderId}`,
    method: 'GET',
  }),
  //TODO - Remove when the backend starts sending the sorted array
  transformResponse(response: IAssessorOption[]) {
    return orderBy(response, [(assessor): string => assessor.label.toLowerCase()], ['asc']);
  },
});
