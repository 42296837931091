import { appMutationFactory } from '@api/api-slice';
import { IErrorResponse } from '@api/error-response.interface';
import { IElectronicInvoiceLog } from '@features/electronic-invoice/electronic-invoice-log.interface';

export const buildRefreshSessionElectronicInvoice = appMutationFactory<number, string | IErrorResponse>({
  query: (id) => ({
    url: `/nmh-sessions/${id}/regenerate_nmh_session_electronic_invoice`,
    method: 'POST',
  }),
});

type SendElectronicInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildSendSessionElectronicInvoice = appMutationFactory<number, SendElectronicInvoiceResponse>({
  query: (id) => ({
    url: `/nmh-sessions/${id}/send_nmh_session_electronic_invoice`,
    method: 'POST',
  }),
});

type GenerateXeroInvoiceResponse = { electronicInvoiceLogs: IElectronicInvoiceLog[] } | IErrorResponse;

export const buildGenerateSessionElectronicInvoice = appMutationFactory<number, GenerateXeroInvoiceResponse>({
  query: (id) => ({
    url: `/nmh-sessions/${id}/generate_session_xero_invoice`,
    method: 'POST',
  }),
});
