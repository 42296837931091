import { appQueryFactory } from '@api/api-slice';
import { validateActionRecords } from '@features/action-records/spec/validate-action-record.spec';
import { IActionRecord } from '@features/action-records/action-record.interface';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { RecordModelNameType, RecordModelNames } from './record-model-name.type';
import { TransportableModel } from '@models/transportable.type';

export type GetModelProgressRecordsQueryParams = TransportableModel<
  { modelName: RecordModelNameType; id: number } & PaginationArgs
>;

export const buildGetModelProgressRecords = appQueryFactory<
  GetModelProgressRecordsQueryParams,
  IPaginable<IActionRecord>
>({
  query: ({ modelName, id }) => ({
    url: `/progress_records/${modelName}/${id}`,
    method: 'GET',
  }),
  transformResponse(response: IPaginable<IActionRecord>, meta, request) {
    validateActionRecords(response.entries, `/progress_records/${request.modelName}/${request.id}`);
    validatePagination(response, `/progress_records/${request.modelName}/${request.id}`);
    return response;
  },
});

export type GetOrderProgressRecordsPayload = {
  modelName: typeof RecordModelNames.Order;
  id: number;
};
