import React from 'react';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { SensitiveInformationField } from '@components/inputs/sensitive-information-field/SensitiveInformationField';
import { Grid, Typography } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ISensitivePersonalInformation } from './sensitive-personal-information.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useDisableRole } from '@features/auth/use-disable-role';
import { UserRoles } from '@features/user/user-role.type';
import { ISensitivePersonalInformationPresence } from './sensitive-personal-information-presence.interface';
import { useSaveSensitivePersonalInformation } from './use-save-sensitive-personal-information';

type Props = {
  isLocked: boolean;
  orderId: number;
  spi: ISensitivePersonalInformation | null;
  setSpi: React.Dispatch<React.SetStateAction<ISensitivePersonalInformation | null>>;
} & ISensitivePersonalInformationPresence;

export const SensitivePersonalInformationDrawer: React.FC<Props> = ({
  isLocked,
  orderId,
  spi,
  setSpi,
  hasConditions,
  hasDisabilityTypes,
  hasDsa1,
  hasMedicalEvidence,
  hasPreAssessmentForm,
  hasDateOfBirth,
  hasNarFile,
}) => {
  const backendLabels = useAppBackendLabels(['disabilityTypeOptions', 'conditionOptions']);
  const { submitHandler, isLoading, control, watch, formState } = useSaveSensitivePersonalInformation(
    orderId,
    spi,
    setSpi,
  );

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);

  const disabilityTypeIds = watch('disabilityTypeIds');
  const filteredConditionOptions = backendLabels.conditionOptions.filter((option) =>
    disabilityTypeIds?.includes(option.disabilityTypeId),
  );

  return (
    <>
      <Typography variant="h4" component="h1">
        Sensitive Personal Information
      </Typography>
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: 1 }}>
          <Grid item xs={4} md={4} lg={4}>
            <SensitiveInformationField hasData={hasDsa1} isLocked={isLocked} label="DSA 1 PDF">
              <ControlledFileField
                fullWidth
                control={control}
                name="dsa1pdfFile"
                label="Upload DSA 1 PDF"
                disabled={isLocked || isDisabledForAssessorAndQA}
                fileDetails={spi?.dsa1PdfFilePath}
                buttonLabel="Preview DSA 1 PDF"
                isPreview
                inputProps={{
                  accept: '.pdf',
                }}
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SensitiveInformationField hasData={hasDisabilityTypes} isLocked={isLocked} label="Disability Types">
              <ControlledAutocomplete
                control={control}
                name="disabilityTypeIds"
                options={backendLabels.disabilityTypeOptions}
                label="Disability Types"
                disabled={isLocked || isDisabledForAssessorAndQA}
                isLoading={backendLabels.isLoading}
                multiple
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SensitiveInformationField hasData={hasConditions} isLocked={isLocked} label="Disability Conditions">
              <ControlledAutocomplete
                control={control}
                name="conditionIds"
                options={filteredConditionOptions}
                label="Disability Conditions"
                disabled={isLocked || isDisabledForAssessorAndQA}
                isLoading={backendLabels.isLoading}
                multiple
                noOptionsText="First select Disability Type"
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <SensitiveInformationField hasData={hasMedicalEvidence} isLocked={isLocked} label="Medical Evidence">
              <ControlledFileField
                fullWidth
                control={control}
                name="medicalEvidenceFiles"
                label="Upload Medical Evidences"
                disabled={isLocked || isDisabledForAssessorAndQA}
                fileDetails={spi?.medicalEvidenceFilesPaths}
                buttonLabel="Preview Medical Evidences"
                isPreview
                isMultiple
                inputProps={{
                  accept: '.pdf',
                }}
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <SensitiveInformationField hasData={hasPreAssessmentForm} isLocked={isLocked} label="Pre Assessment Form">
              <ControlledFileField
                fullWidth
                control={control}
                name="preAssessmentFormFile"
                label="Upload Pre Assessment Form"
                disabled={isLocked || isDisabledForAssessorAndQA}
                fileDetails={spi?.preAssessmentFormFilePath}
                buttonLabel="Preview Form"
                isPreview
                inputProps={{
                  accept: '.pdf',
                }}
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <SensitiveInformationField
              hasData={hasDateOfBirth}
              isLocked={isLocked || isDisabledForAssessorAndQA}
              label="Date Of Birth"
            >
              <ControlledDatePicker
                control={control}
                name="dateOfBirth"
                label="Date Of Birth"
                disabled={isLocked || isDisabledForAssessorAndQA}
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <SensitiveInformationField
              hasData={hasNarFile}
              isLocked={isLocked}
              label="Previous Needs Assessment Report"
            >
              <ControlledFileField
                fullWidth
                control={control}
                name="narFile"
                label="Previous Needs Assessment Report"
                disabled={isLocked || isDisabledForAssessorAndQA}
                fileDetails={spi?.narFilePath}
                buttonLabel="Previous Needs Assessment Report"
                isPreview
                inputProps={{
                  accept: '.pdf',
                }}
              />
            </SensitiveInformationField>
          </Grid>
          <Grid item xs={4} md={8} lg={12}>
            <AppLoadingButton
              fullWidth
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !formState.isDirty}
              sx={{ mt: 2 }}
            >
              Save
            </AppLoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
