import { appMutationFactory } from '@api/api-slice';
import { ISalesOrderLine } from '../sales-order-line.interface';
import { IErrorResponse } from '@api/error-response.interface';

type Response = {
  salesOrderLines: ISalesOrderLine[];
};

export const buildUpdateSalesOrderLines = appMutationFactory<number, Response | IErrorResponse>({
  query: (orderId) => {
    return {
      url: `/sales-order/${orderId}`,
      method: 'PATCH',
    };
  },
});
