import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { TransportableModel } from '@models/transportable.type';
import { IEquipmentInvoice } from '../equipment-invoice.interface';

type EquipmentInvoiceQueryParams = TransportableModel<PaginationArgs>;
type EquipmentInvoiceResponse = IPaginable<IEquipmentInvoice>;

export const buildGetEquipmentInvoices = appQueryFactory<EquipmentInvoiceQueryParams, EquipmentInvoiceResponse>({
  query: (params) => ({
    url: '/technical-invoicing-summary',
    method: 'GET',
    params,
  }),
});
