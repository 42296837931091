import { appQueryFactory } from '@api/api-slice';
import { IPaginable, PaginationArgs } from '@features/pagination/paginable.interface';
import { IAssessor, IAssessorSummary } from './assessor.interface';
import { TransportableModel } from '@models/transportable.type';
import { validatePagination } from '@features/pagination/spec/pagination.spec';
import { validateAssessors } from './spec/validate-assessors.spec';
import { IAssessorFeedbackSummary } from './assessor-feedback-summary.interface';

const Endpoints = {
  assessors: '/assessors',
  assessorFeedbackSummaries: '/assessor-feedback-summaries',
} as const;

type FilterAssessorsQueryParams = TransportableModel<PaginationArgs>;
export type FilterAssessorsResponse = IPaginable<IAssessor>;
export const buildGetAssessors = appQueryFactory<FilterAssessorsQueryParams, FilterAssessorsResponse>({
  query: (params) => ({
    url: Endpoints.assessors,
    method: 'GET',
    params,
  }),
  transformResponse(response: FilterAssessorsResponse) {
    validateAssessors(response, Endpoints.assessors);
    validatePagination(response, Endpoints.assessors);
    return response;
  },
});

interface IAssessorResponse {
  assessor: IAssessorSummary;
}
export const buildGetAssessor = appQueryFactory<number, IAssessorResponse>({
  query: (id) => ({ url: `${Endpoints.assessors}/${id}`, method: 'GET' }),
});

type AssessorFeedbackSummariesQueryParams = TransportableModel<{ assessorId: number } & PaginationArgs>;
type AssessorFeedbackSummariesResponse = IPaginable<IAssessorFeedbackSummary>;
export const buildGetAssessorFeedbackSummaries = appQueryFactory<
  AssessorFeedbackSummariesQueryParams,
  AssessorFeedbackSummariesResponse
>({
  query: ({ assessorId, ...params }) => ({
    url: `${Endpoints.assessorFeedbackSummaries}/${assessorId}`,
    method: 'GET',
    params,
  }),
});
